import React from "react"
import { Row, Col } from 'reactstrap'

const OfficersIntroductionSection = () => (
	<div>
		<Row>
		</Row>
		<Row>
		
		</Row>
	</div>
)

export default OfficersIntroductionSection
